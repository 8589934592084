import {
  EXPORT_FORMAT_NAMES,
  ExportFormats,
  ExportJobTypes,
  ExportTransportTypes,
  ReportNames,
} from 'constants/exportFormats';

import { useEffect, useMemo, useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { Box } from '@mui/material';
import { getUserInfo } from 'config/requests';
import { getUnixTime } from 'date-fns';
import { useReportExport } from 'hooks/queries/useReportExport';
import _ from 'lodash';
import { SortPayload, useNotify, WithListContext } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { useQuery } from 'react-query';
import { Alert } from 'shared/mui/Alert/Alert';
import { DateRangePickerValue } from 'shared/mui/DatePicker/DateRangePicker/DateRangePicker';
import { NotifyDialog } from 'shared/mui/Dialogs';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterResetButton,
  FilterIdButton,
  FilterClearButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterIdChip } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterIdDialog } from 'shared/mui/NewFilter/FilterId/FilterIdDialog/FilterIdDialog';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { FilterIdTypes, useIdFilters } from 'shared/mui/NewFilter/hooks/useIdFilters';
import { Typography } from 'shared/mui/Typography';
import { ExportEditButton } from 'shared/react-admin/Export/ExportEditButton/ExportEditButton';
import { ReportExportDialogMessage } from 'shared/widgets/ReportExport/ReportExportDialogMessage';
import { ExportFormat, Order, OrderDispute } from 'types';
import * as yup from 'yup';

import { OrderListNotFoundIdAlert } from './OrderListNotFoundIdAlert';
import { OrderListFilter } from './OrdersListFilter/OrdersListFilter';
import { OrdersListFilterChips } from './OrdersListFilterChips/OrdersListFilterChips';
import { OrdersListLocalDatagrid } from './OrdersListLocalDatagrid';
import { cleanEmpty } from '../../../utils/cleanEmpty';
import { AppList } from '../../Common/List';
import { AppPanelHeader, AppPanelHeaderToolbar } from '../../Common/PanelHeader';
import OrdersDisputeCreateDialog from '../components/OrderDispute/OrdersDisputeCreateDialog';
import OrdersDisputeEditDialog from '../components/OrderDispute/OrdersDisputeEditDialog';
import { EXPORT_FIELDS_ORDERS } from '../constants/exportFields';
import { OrdersListActions } from '../index';

export type OrdersListFilterFormValues = {
  appliedDate: [DateRangePickerValue, DateRangePickerValue];
  amount: { gte: string | null; lte: string | null };
  currency: string[];
  direction: string | null;
  merchant: string | null;
  gateway: string[];
  provider: string | null;
  method: string[];
  statusNew: string[];
};

export const OrdersListNew = (): JSX.Element => {
  const [selectedOrder, setSelectedOrder] = useState<Order>();
  const [selectedDispute, setSelectedDispute] = useState<OrderDispute | undefined>();
  const [isOpenDispute, setIsOpenDispute] = useState(false);
  const [isOpenNotifyDialog, setIsOpenNotifyDialog] = useState(false);
  const [isSendReportOnEmail, setIsSendReportOnEmail] = useState(false);
  const [exportFormat, setExportFormat] = useState<ExportFormat>(ExportFormats.Excel);
  const [currentIdFilter, setCurrentIdFilter] = useState<FilterIdTypes>(FilterIdTypes.Id);
  const [isOpenIdDialog, setIsOpenIdDialog] = useState<boolean>(false);

  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<OrdersListFilterFormValues>({
      mode: 'all',
      resolver: yupResolver<any>(
        yup.object().shape({
          amount: yup
            .object()
            .shape({
              gte: yup
                .number()
                .typeError('Должно быть числом')
                .positive('Должно быть положительным')
                .when('lte', {
                  is: (val: number | undefined) => Boolean(val),
                  then: () =>
                    yup
                      .number()
                      .typeError('Должно быть числом')
                      .nullable()
                      .lessThan(yup.ref('lte'), 'Должно быть меньше чем "Сумма до"'),
                })
                .integer('Должно быть целым числом')
                .nullable(),
              lte: yup
                .number()
                .typeError('Должно быть числом')
                .positive('Должно быть положительным')
                .moreThan(yup.ref('gte'), 'Должно быть больше чем "Сумма от"')
                .integer('Должно быть целым числом')
                .nullable(),
            })
            .nullable(),
        }),
      ),
      defaultValues: {
        merchant: null,
        appliedDate: [null, null],
        amount: {
          lte: null,
          gte: null,
        },
        currency: [],
        direction: null,
        gateway: [],
        provider: null,
        method: [],
        statusNew: [],
      },
    });

  const {
    setAppliedFilter: setAppliedIdFilter,
    onSubmit: onSubmitIds,
    onReset: onResetIds,
    appliedIdFilters,
    form: formId,
  } = useIdFilters({
    defaultValues: {
      merchantReference: [],
      id: [],
      providerReferenceId: [],
    },
  });

  const merchantWatched = form.watch('merchant');
  const providerWatched = form.watch('provider');
  const gatewayWatched = form.watch('gateway');

  useEffect(() => {
    if (merchantWatched === appliedFilters.merchant) {
      return;
    }

    form.resetField('provider');
    form.resetField('gateway');
    form.resetField('method');
  }, [merchantWatched]);

  useEffect(() => {
    if (providerWatched === appliedFilters.provider) {
      return;
    }

    form.resetField('gateway');
    form.resetField('method');
  }, [providerWatched]);

  useEffect(() => {
    if (_.isEqual(gatewayWatched, appliedFilters.gateway)) {
      return;
    }

    form.resetField('method');
  }, [gatewayWatched]);

  const listFilters = useMemo(() => {
    const { appliedDate = [null, null], provider, ...restFilters } = appliedFilters;
    return cleanEmpty({
      ...restFilters,
      ...appliedIdFilters,
      'gateway.provider': provider,
      'createdAt[gte]': appliedDate?.[0] ? getUnixTime(appliedDate?.[0]) : undefined,
      'createdAt[lte]': appliedDate?.[1] ? getUnixTime(appliedDate?.[1]) : undefined,
    });
  }, [appliedFilters, appliedIdFilters]);

  const notify = useNotify();

  const { mutate: exportMutate, isLoading: isExportLoading } = useReportExport({
    filters: {
      ...listFilters,
      exportType: exportFormat,
      jobType: ExportJobTypes.Orders,
      ...(isSendReportOnEmail && { notificationTransport: [ExportTransportTypes.Email] }),
    },
    preferenceKey: ReportNames.Orders,
    onSuccess: () => {
      notify(
        `Файл экспорта заказов формируется ${isSendReportOnEmail ? 'и будет отправлен вам на почту' : ''}`,
        {
          type: 'info',
        },
      );
      setIsOpenNotifyDialog(false);
    },
    onError: (error) => {
      notify(error.data?.errors[0]?.title, { type: 'error' });
    },
  });

  const { data: user } = useQuery('me', getUserInfo);
  const isListFilterExist = Object.keys(listFilters).length > 0;

  const formValuesWatched = form.watch();
  const idsFormValuesWatched = formId.watch();

  const isFormValuesExist = Object.values(cleanEmpty(formValuesWatched)).length > 0;
  const isIdFormValuesExist = Object.values(cleanEmpty(idsFormValuesWatched)).length > 0;

  return (
    <AppList
      empty={false}
      filter={listFilters}
      pagination={<></>}
      queryOptions={{ enabled: isListFilterExist }}
      sort={{} as SortPayload}
    >
      <WithListContext
        render={({ isFetching, data: orders = [], total }) => {
          const notFoundIds = appliedIdFilters.id?.filter(
            (id) => id && !orders.some((order) => order.originId === id),
          );
          const notFoundMerchantIds = appliedIdFilters.merchantReference?.filter(
            (id) => !orders.some((order) => order.merchantReference === id),
          );
          const notFoundProviderIds = appliedIdFilters.providerReferenceId?.filter(
            (id) => !orders.some((order) => order.providerReferenceId === id),
          );

          return (
            <>
              {selectedDispute ? (
                <OrdersDisputeEditDialog
                  dispute={selectedDispute}
                  onClose={() => setIsOpenDispute(false)}
                  open={isOpenDispute}
                  order={selectedOrder}
                />
              ) : (
                <OrdersDisputeCreateDialog
                  onClose={() => setIsOpenDispute(false)}
                  open={isOpenDispute}
                  order={selectedOrder}
                />
              )}
              <AppPanelHeader>
                <AppPanelHeaderToolbar
                  actions={[
                    <OrdersListActions
                      exportFormat={exportFormat}
                      key="orders_actions"
                      onExportFormatsButtonClick={() => setIsOpenNotifyDialog(true)}
                      setExportFormat={setExportFormat}
                    />,
                  ]}
                  titleText="Заказы"
                />
              </AppPanelHeader>
              <FilterToolbar
                leftActionsSlot={[
                  <FilterIdButton
                    disabled={isFormValuesExist}
                    key="filter-by-id"
                    onClick={() => {
                      setIsOpenIdDialog(true);
                    }}
                  />,
                  <FilterButton
                    disabled={isIdFormValuesExist}
                    key="filter"
                    onClick={toggleDrawer(true)}
                  />,
                  <FilterClearButton
                    key={`clear-${currentIdFilter}`}
                    onClick={() => {
                      onReset();
                      onResetIds(currentIdFilter);
                    }}
                    visible={isListFilterExist}
                  />,
                ]}
              />
              <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
                <FormProvider {...form}>
                  <form onSubmit={form.handleSubmit(onSubmit)}>
                    <FilterDrawerContent>
                      <OrderListFilter />
                    </FilterDrawerContent>
                    <FilterDrawerActionsButtons>
                      <FilterApplyButton type="submit" />
                      <FilterResetButton label="Очистить" onReset={onReset} />
                    </FilterDrawerActionsButtons>
                  </form>
                </FormProvider>
              </FilterDrawer>
              <FilterChipsToolbar
                appliedFilters={appliedFilters}
                appliedIdFilters={appliedIdFilters}
              >
                <FilterContextProvider
                  appliedFilters={appliedFilters}
                  setAppliedFilter={setAppliedFilter}
                >
                  <FormProvider {...form}>
                    <OrdersListFilterChips />
                  </FormProvider>
                </FilterContextProvider>
                <FilterContextProvider
                  appliedFilters={appliedIdFilters}
                  setAppliedFilter={setAppliedIdFilter}
                >
                  <FormProvider {...formId}>
                    <FilterIdChip
                      label="ID Kubera"
                      limitTags={2}
                      name="id"
                      onClick={() => {
                        setIsOpenIdDialog(true);
                        setCurrentIdFilter(FilterIdTypes.Id);
                      }}
                    />
                    <FilterIdChip
                      label="ID Мерчантов"
                      limitTags={2}
                      name="merchantReference"
                      onClick={() => {
                        setIsOpenIdDialog(true);
                        setCurrentIdFilter(FilterIdTypes.Merchant);
                      }}
                    />
                    <FilterIdChip
                      label="ID Провайдеров"
                      limitTags={2}
                      name="providerReferenceId"
                      onClick={() => {
                        setIsOpenIdDialog(true);
                        setCurrentIdFilter(FilterIdTypes.Provider);
                      }}
                    />
                  </FormProvider>
                </FilterContextProvider>
              </FilterChipsToolbar>
              <FormProvider {...formId}>
                <FilterIdDialog
                  currentIdFilter={currentIdFilter}
                  notFoundIds={notFoundIds}
                  notFoundMerchantIds={notFoundMerchantIds}
                  notFoundProviderIds={notFoundProviderIds}
                  onChange={(currentFilter) => setCurrentIdFilter(currentFilter)}
                  onClose={() => setIsOpenIdDialog(false)}
                  onReset={onResetIds}
                  onSubmit={onSubmitIds}
                  open={isOpenIdDialog}
                />
              </FormProvider>
              {(total || 0) >= 1001 && !isFetching && isListFilterExist && (
                <Alert severity="warning" sx={{ margin: '15px 0 10px 0' }}>
                  Применённый фильтр слишком широкий, поэтому показано только 1000 записей. Чтобы
                  получить все результаты фильтрации, сделайте экспорт.
                </Alert>
              )}
              <OrderListNotFoundIdAlert ids={notFoundIds} isFetching={isFetching} />
              <OrderListNotFoundIdAlert ids={notFoundMerchantIds} isFetching={isFetching} />
              <OrderListNotFoundIdAlert ids={notFoundProviderIds} isFetching={isFetching} />
              <Box
                sx={{
                  minHeight: '100%',
                  display: 'initial',
                }}
              >
                {!isListFilterExist && (
                  <Alert severity="info">
                    Чтобы сформировать отчет, выберете период и необходимые параметры в фильтре.
                  </Alert>
                )}
                {isListFilterExist && (
                  <OrdersListLocalDatagrid
                    isLoading={isFetching}
                    orders={(orders as Order[]) || []}
                    setIsOpenDispute={setIsOpenDispute}
                    setSelectedDispute={setSelectedDispute}
                    setSelectedOrder={setSelectedOrder}
                    total={total || 0}
                  />
                )}
                <NotifyDialog
                  isLoading={isExportLoading}
                  message={
                    <ReportExportDialogMessage
                      exportFormat={EXPORT_FORMAT_NAMES[exportFormat]}
                      isSendReportOnEmail={isSendReportOnEmail}
                      onChange={() => setIsSendReportOnEmail((prev) => !prev)}
                      userEmail={user?.corporateEmail || user?.email || 'почта неизвестна'}
                    />
                  }
                  onClose={() => setIsOpenNotifyDialog(false)}
                  onSubmit={exportMutate}
                  open={isOpenNotifyDialog}
                  title={
                    <>
                      <Typography
                        component="span"
                        sx={{ verticalAlign: 'middle' }}
                        variant="inherit"
                      >
                        Экспорт отчета
                      </Typography>
                      <ExportEditButton
                        fields={EXPORT_FIELDS_ORDERS}
                        preferenceKey={ReportNames.Orders}
                      />
                    </>
                  }
                />
              </Box>
            </>
          );
        }}
      />
    </AppList>
  );
};
