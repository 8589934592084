import { TextInput, useGetList } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import { DateTimeRangePicker } from 'shared/mui/DatePicker/DateTimeRangePicker/DateTimeRangePicker';
import { FilterDrawerRow } from 'shared/mui/NewFilter/FilterDrawer';
import {
  AutocompleteInputShrink,
  AutocompleteMultiselectInput,
  EnumAutocompleteInputShrink,
} from 'shared/react-admin/Inputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { cleanEmpty } from 'utils/cleanEmpty';

export const OrderListFilter: React.FC = () => {
  const { control, watch, trigger, getValues } = useFormContext();

  const { data: orderStatuses } = useGetList('new/order_statuses');

  const triggerAmountLteValidation = () => trigger(['amount.lte']);
  const triggerAmountGteValidation = () => trigger(['amount.gte']);

  const merchantWatched = watch('merchant');
  const providerWatched = watch('provider');
  const gatewayWatched = watch('gateway');

  return (
    <>
      <Controller
        control={control}
        name="appliedDate"
        render={({ field: { onChange, onBlur, value } }) => (
          <DateTimeRangePicker
            label="Время создания / период"
            maxDate={new Date()}
            onBlur={onBlur}
            onChange={onChange}
            value={value}
          />
        )}
      />
      <ReferenceInput perPage={100} reference="merchants" source="merchant">
        <AutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ name: searchText })}
          fullWidth
          helperText={false}
          label="Мерчант"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceInput
        filter={cleanEmpty({ merchantAccounts: { merchants: merchantWatched } })}
        perPage={100}
        reference="providers"
        source="provider"
      >
        <AutocompleteInputShrink
          clearOnBlur={false}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Провайдер"
          name="provider"
          optionText="name"
          variant="outlined"
        />
      </ReferenceInput>
      <ReferenceArrayInput
        filter={cleanEmpty({
          merchantAccounts: { merchants: merchantWatched },
          provider: providerWatched,
        })}
        name="gateway"
        perPage={100}
        reference="gateways"
        source="gateway"
      >
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Шлюзы"
          limitTags={1}
          name="gateway"
          optionText="name"
        />
      </ReferenceArrayInput>
      <ReferenceArrayInput
        filter={cleanEmpty({
          gateway: gatewayWatched,
        })}
        name="method"
        perPage={100}
        reference="methods"
        source="method"
      >
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Методы"
          limitTags={1}
          name="method"
          optionText="name"
        />
      </ReferenceArrayInput>
      <EnumAutocompleteInputShrink
        filterToQuery={(searchText: string) => ({ description: searchText })}
        fullWidth
        helperText={false}
        label="Направление"
        name="direction"
        optionText="description"
        optionValue="value"
        resource="directions"
        source="direction"
        variant="outlined"
      />
      <AutocompleteMultiselectInput
        InputLabelProps={{ shrink: true }}
        defaultValue={getValues('statusNew')}
        getOptionLabel={(option, options) =>
          options?.find((opt) => opt.value === option)?.description
        }
        helperText={false}
        label="Статус"
        limitTags={1}
        name="statusNew"
        optionText="description"
        optionValue="value"
        options={orderStatuses || []}
      />
      <ReferenceArrayInput name="currency" perPage={50} reference="currencies" source="currency">
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          filterToQuery={(searchText: string) => ({ name: searchText })}
          helperText={false}
          label="Валюта"
          limitTags={1}
          name="currency"
          optionText="name"
        />
      </ReferenceArrayInput>
      <FilterDrawerRow>
        <TextInput
          InputLabelProps={{ shrink: true }}
          label="Сумма от"
          onChange={triggerAmountLteValidation}
          resettable
          source="amount.gte"
          variant="outlined"
        />
        <TextInput
          InputLabelProps={{ shrink: true }}
          label="Сумма до"
          onChange={triggerAmountGteValidation}
          resettable
          source="amount.lte"
          variant="outlined"
        />
      </FilterDrawerRow>
    </>
  );
};
