import { queryClient } from 'App';
import { Form } from 'react-admin';
import { Button } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';

import {
  MerchantsShowNotificationsForm,
  MerchantsShowNotificationsFormValues,
} from '../components/MerchantsShowNotificationsForm/MerchantsShowNotificationsForm';
import { CreateStyled } from '../components/MerchantsShowNotificationsForm/MerchantsShowNotificationsForm.styled';
import { useCreateMerchantNotification } from '../hooks/useCreateMerchantNotification';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  merchantId: string;
};

export const MerchantsShowNotificationsCreateDialog: React.FC<Props> = ({
  open,
  onClose,
  merchantId,
}) => {
  const { createMerchantNotification, isLoading } = useCreateMerchantNotification(
    merchantId,
    () => {
      onClose();
      queryClient.invalidateQueries('merchant_notification_settings');
    },
  );

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open}>
      <CreateStyled>
        <Form
          onSubmit={(data) =>
            createMerchantNotification(data as MerchantsShowNotificationsFormValues)
          }
        >
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Создание настроек уведомления"
          />
          <MerchantsShowNotificationsForm />
          <CardActionsButtons
            leftActionsSlot={[
              <Button
                isLoading={isLoading}
                key="create-button"
                label="Создать"
                type="submit"
                variant="contained"
              />,
              <Button key="cancel-button" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
          />
        </Form>
      </CreateStyled>
    </Dialog>
  );
};
