import { useState } from 'react';

import { CancelButton } from 'shared/mui/Buttons';
import { SaveButtonValidated } from 'shared/react-admin/Buttons';
import { CreateGuesserPage } from 'shared/react-admin/Pages';
import { User } from 'types';

import { UsersForm } from './components/UsersForm';

export const UsersCreate = (): JSX.Element => {
  const [hasCorporateEmail, setHasCorporateEmail] = useState(false);

  const transform = (data: User) => {
    return {
      ...data,
      corporateEmail: hasCorporateEmail ? data.corporateEmail : null,
    };
  };

  return (
    <CreateGuesserPage
      leftActionsSlot={[
        <SaveButtonValidated key="save-buttton" label="Создать" />,
        <CancelButton key="cancel-button" linkType="back" variant="outlined" />,
      ]}
      listName="Пользователи"
      mutationOptions={{ onError: () => undefined }}
      title="Создание пользователя"
      transform={transform}
    >
      <UsersForm
        hasCorporateEmail={hasCorporateEmail}
        setHasCorporateEmail={setHasCorporateEmail}
      />
    </CreateGuesserPage>
  );
};
