import { useShowController } from 'react-admin';
import { ShowPageTabbed } from 'shared/react-admin/Pages';
import { Tab } from 'shared/react-admin/Tab/Tab';
import { ApiClient } from 'types';

import { ApiClientsShowCommonInfo } from './ApiClientsShowCommonInfo/ApiClientsShowCommonInfo';
import { ApiClientsShowTopPanel } from './ApiClientsShowTopPanel/ApiClientsShowTopPanel';

export const ApiClientsShow = () => {
  const { record } = useShowController<ApiClient>();

  return (
    <ShowPageTabbed
      headerTitle={{ titleText: record?.name }}
      listName="Клиенты Api"
      title="Управление клиентом API"
      topPanel={<ApiClientsShowTopPanel />}
    >
      <Tab label="информация">
        <ApiClientsShowCommonInfo record={record} />
      </Tab>
    </ShowPageTabbed>
  );
};
