import {
  AccountBox,
  CreditCard,
  ForkRight,
  MonetizationOn,
  Person,
  Power,
  Security,
  ShoppingCart,
  Work,
  Rule,
} from '@mui/icons-material';
import { MenuItem } from '@mui/material';

type MenuList = (MenuItem | MultiLevelMenuItem)[];

export type MultiLevelMenuItem = {
  icon: React.ReactElement;
  title: string;
  items: MenuItem[];
};

type MenuItem = {
  resource: string;
  title: string;
  icon?: React.ReactElement;
};

const FINANCE = {
  title: 'Финансы',
  icon: <MonetizationOn />,
  items: [
    {
      resource: 'work_teams',
      title: 'Рабочие группы',
    },
    {
      resource: 'provider_account_news',
      title: 'Личные кабинеты',
    },
    {
      resource: 'balance_report',
      title: 'Отчёт о балансах',
    },
    {
      resource: 'cumulative_report',
      title: 'Отчет по нарастающему итогу',
    },
    {
      resource: 'orders_report',
      title: 'Отчет по транзакциям',
    },
  ],
};

const USERS = {
  title: 'Пользователи',
  icon: <Person />,
  items: [
    {
      resource: 'users',
      title: 'Пользователи системы',
    },
    {
      resource: 'roles',
      title: 'Роли',
    },
    {
      resource: 'user_actions',
      title: 'Пользовательские действия',
    },
  ],
};

const COMPANIES = {
  title: 'Компании',
  icon: <Work />,
  items: [
    {
      resource: 'companies',
      title: 'Компании',
    },
    {
      resource: 'merchant_spaces',
      title: 'Кабинеты мерчантов',
    },
    {
      resource: 'merchant_spaces_users',
      title: 'Пользователи мерчантов',
    },
  ],
};

const API_CLIENTS = {
  title: 'Клиенты API',
  icon: <Security />,
  items: [
    {
      resource: 'api_clients',
      title: 'Клиенты API',
    },
    {
      resource: 'api_client_white_ip_addresses',
      title: 'Белые IP-адреса',
    },
    {
      resource: 'api_keys',
      title: 'Ключи API',
    },
  ],
};

const MERCHANTS = {
  title: 'Мерчанты',
  icon: <AccountBox />,
  resource: 'merchants',
};

const GATEWAY_RULES = {
  title: 'Правила',
  icon: <Rule />,
  items: [
    {
      resource: 'gateway_rules',
      title: 'Правила',
    },
    {
      resource: 'order_methods',
      title: 'Проверка правил и методов',
    },
  ],
};

const ORDERS = {
  title: 'Заказы',
  icon: <ShoppingCart />,
  items: [
    {
      resource: 'orders',
      title: 'Заказы',
    },
  ],
};

const METHODS = {
  title: 'Методы',
  icon: <CreditCard />,
  items: [
    {
      resource: 'methods',
      title: 'Методы',
    },
    {
      resource: 'method_logos',
      title: 'Логотипы методов',
    },
  ],
};

const GATEWAYS = {
  title: 'Шлюзы',
  icon: <Power />,
  items: [
    {
      resource: 'gateways',
      title: 'Шлюзы',
    },
    {
      resource: 'limits',
      title: 'Лимиты',
    },
    {
      resource: 'gateway_deactivation_reasons',
      title: 'Причины деактивации шлюза',
    },
  ],
};

const PROVIDERS = {
  title: 'Провайдеры',
  icon: <ForkRight />,
  items: [
    {
      resource: 'providers',
      title: 'Провайдеры',
    },
    {
      resource: 'commissions',
      title: 'Комиссии',
    },
    {
      resource: 'provider_white_ip_addresses',
      title: 'Белые IP-адреса',
    },
    {
      resource: 'providers_report',
      title: 'Отчет по провайдерам',
    },
  ],
};

export const getMenuList = (): MenuList => [
  FINANCE,
  USERS,
  COMPANIES,
  API_CLIENTS,
  MERCHANTS,
  GATEWAY_RULES,
  ORDERS,
  METHODS,
  GATEWAYS,
  PROVIDERS,
];
