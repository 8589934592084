import { queryClient } from 'App';
import { Button, CancelButton } from 'shared/mui/Buttons';
import { Card, CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Dialog, DialogProps } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Typography } from 'shared/mui/Typography';
import { MerchantNotification } from 'types';

import { useEditMerchantNotification } from '../../../hooks/useEditMerchantNotification';

type Props = {
  notificationTransportName: string;
  onClose: VoidFunction;
  notification: MerchantNotification;
} & DialogProps;

export const MerchantsShowNotificationsDeliveryChannelFieldSwitchConfirmOff: React.FC<Props> = ({
  notificationTransportName,
  open,
  onClose,
  notification,
}) => {
  const { isLoading, mutate } = useEditMerchantNotification(notification.id, () => {
    onClose();
    queryClient.invalidateQueries('merchant_notification_settings');
  });

  return (
    <Dialog open={open}>
      <Card sx={{ width: '600px' }}>
        <CardHeader
          actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
          title="Выключение настроек уведомления"
        />
        <CardContent>
          <Typography>
            {`Уведомление ID ${notification.id} с типом ${notificationTransportName} будет временно выключено`}
          </Typography>
        </CardContent>
        <CardActionsButtons
          rightActionsSlot={[
            <CancelButton key="cancel-button" onClick={onClose} variant="outlined" />,
            <Button
              color="error"
              isLoading={isLoading}
              key="save-button"
              label="Выключить"
              onClick={() => mutate({ active: false })}
              variant="contained"
            />,
          ]}
        />
      </Card>
    </Dialog>
  );
};
