import { TableRowWrapper } from 'shared/mui/Table';
import { YesIcon } from 'shared/react-admin/Fields/BooleanField/BooleanField';

type Props = {
  label: string;
  key: string;
};

export const MerchantsShowNotificationsShowDialogRuleField: React.FC<Props> = ({ label }) => (
  <TableRowWrapper title={label}>
    <YesIcon />
  </TableRowWrapper>
);
