import { SecurityIssue } from 'types';

import { SECURITY_ISSUES } from '../constants/securityIssues';

const NO_ISSUES = {
  title: 'Нет проблем',
  color: 'rgba(76, 175, 80, 1)',
};

export const getSecurityIssue = (issue?: SecurityIssue) =>
  issue ? SECURITY_ISSUES[issue] : NO_ISSUES;
