import { useEffect } from 'react';

import { useFilters } from 'hooks/useFilters';
import { FormProvider } from 'react-hook-form';
import { FilterActions } from 'shared/mui/Filter/FilterActions/FilterActions';
import { FilterBox } from 'shared/mui/Filter/FilterBox/FilterBox';
import { FilterBaseApplyButton } from 'shared/mui/Filter/FilterButtons/FilterBaseApplyButton/FilterBaseApplyButton';
import { FilterBaseResetButton } from 'shared/mui/Filter/FilterButtons/FilterBaseResetButton/FilterBaseResetButton';
import { FilterFieldGroup } from 'shared/mui/Filter/FilterFieldGroup/FilterFieldGroup';
import { TextInput } from 'shared/react-admin/Inputs';
import { AutocompleteMultiselectInputShrink } from 'shared/react-admin/Inputs/AutocompleteMultiselectInputShrink/AutocompleteMultiselectInputShrink';
import { ReferenceArrayInput } from 'shared/react-admin/ReferenceInputs';

type Props = {
  merchantId: string | undefined;
};

export const MerchantShowCommissionsListFilter: React.FC<Props> = ({ merchantId }) => {
  const { onSubmit, form, resetFilter } = useFilters(
    { merchant: merchantId, name: '', provider: [] },
    { merchant: merchantId, name: '', provider: [] },
  );

  useEffect(() => resetFilter, []);

  return (
    <FormProvider {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <FilterBox>
          <FilterFieldGroup>
            <TextInput
              InputLabelProps={{ shrink: true }}
              helperText={false}
              label="Название"
              source="name"
              sx={{ marginTop: '8px !important', marginBottom: '8px' }}
              variant="outlined"
            />
            <ReferenceArrayInput
              filter={{
                merchantAccounts: {
                  merchants: [merchantId],
                },
              }}
              name="provider"
              reference="providers"
              source="provider"
            >
              <AutocompleteMultiselectInputShrink
                helperText={false}
                label="Провайдер"
                name="provider"
                optionText="name"
              />
            </ReferenceArrayInput>
            <FilterActions sx={{ marginTop: 0 }}>
              <FilterBaseApplyButton key="apply-button" type="submit" />
              <FilterBaseResetButton key="reset-button" onClick={resetFilter} />
            </FilterActions>
          </FilterFieldGroup>
        </FilterBox>
      </form>
    </FormProvider>
  );
};
