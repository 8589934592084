import React, { useState } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import { commissionSchema } from 'components/Commissions/schemas/schema';
import { usePermissions } from 'hooks/usePermissions';
import { Form, RecordContextProvider, useNotify, useUpdate } from 'react-admin';
import { FieldValues } from 'react-hook-form';
import { Button, DeleteButton } from 'shared/mui/Buttons';
import { CardActionsButtons, CardHeader } from 'shared/mui/Card';
import { Dialog } from 'shared/mui/Dialogs';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { Commission, Permission } from 'types';

import {
  CommissionsFormValues,
  MerchantShowCommissionsForm,
} from '../components/MerchantShowCommissionsForm';

type Props = {
  open: boolean;
  onClose: () => void;
  merchantId: string | undefined;
  onDelete: (record: Commission) => void;
  record: Commission | undefined;
};

export const MerchantShowCommissionsEditDialog: React.FC<Props> = ({
  open,
  onClose,
  merchantId,
  record,
  onDelete,
}) => {
  const { hasResourcePermissions } = usePermissions();
  const [update] = useUpdate();
  const [errors, setErrors] = useState<string[]>([]);
  const notify = useNotify();

  const onSubmit = (formValues: CommissionsFormValues) => {
    update(
      'commissions',
      {
        id: record?.id,
        data: {
          ...formValues,
          merchant: merchantId,
          min: formValues.min ? formValues.min + '' : null,
          max: formValues.max ? formValues.max + '' : null,
          fix: formValues.fix ? formValues.fix + '' : null,
          percent: formValues.percent ? formValues.percent + '' : null,
        },
        previousData: record,
      },
      {
        onSuccess: () => {
          notify('Комиссия успешно создана!', {
            type: 'success',
          });
          onClose();
        },
        onError: (error: any) => {
          setErrors(error?.message.split('\n'));
        },
      },
    );
  };

  return (
    <Dialog maxWidth="lg" onClose={onClose} open={open} transitionDuration={0}>
      <RecordContextProvider value={record}>
        <Form
          defaultValues={{ merchant: merchantId }}
          onSubmit={onSubmit as any}
          resolver={yupResolver<FieldValues>(commissionSchema)}
        >
          <CardHeader
            actions={[<CloseIconButton key="close-button" onClose={onClose} />]}
            title="Редактирование комиссии"
          />
          <MerchantShowCommissionsForm errors={errors} merchantId={merchantId} />
          <CardActionsButtons
            leftActionsSlot={[
              <Button key="update" label="Сохранить" type="submit" variant="contained" />,
              <Button key="cancel" label="Отменить" onClick={onClose} variant="outlined" />,
            ]}
            rightActionsSlot={
              hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) && (
                <DeleteButton
                  onClick={() => {
                    onClose();
                    record && onDelete(record);
                  }}
                  variant="outlined"
                />
              )
            }
          />
        </Form>
      </RecordContextProvider>
    </Dialog>
  );
};
