const enum SecurityIssuesTypes {
  NoWhitelist = 'ip_whitelist_not_set',
  Open = 'open_for_all',
  NoProblem = 'no_problems_found',
}

export const SECURITY_ISSUES = {
  [SecurityIssuesTypes.NoWhitelist]: {
    title: 'Whitelist не настроен',
    color: 'rgba(239, 83, 80, 1)',
  },
  [SecurityIssuesTypes.Open]: {
    title: 'Немаршрутизир. адрес',
    color: 'rgba(239, 83, 80, 1)',
  },
  [SecurityIssuesTypes.NoProblem]: {
    title: 'Нет проблем',
    color: 'rgba(76, 175, 80, 1)',
  },
};

export const SECURITY_ISSUES_SELECT_ITEMS = [
  { id: SecurityIssuesTypes.NoWhitelist, name: 'Whitelist не настроен' },
  { id: SecurityIssuesTypes.Open, name: 'Немаршрутизир. адрес' },
  { id: SecurityIssuesTypes.NoProblem, name: 'Нет проблем' },
];
