import { useEffect } from 'react';

import { getMerchantNotificationsEventNames } from 'config/requests';
import { number, required, useRecordContext, email as validateEmail } from 'react-admin';
import { useFormContext, useWatch } from 'react-hook-form';
import { useQuery } from 'react-query';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { BooleanInput } from 'shared/react-admin/Inputs';
import { SelectInputShrink } from 'shared/react-admin/Inputs/SelectInputShrink/SelectInputShrink';
import { TextInputShrink } from 'shared/react-admin/Inputs/TextInputs/TextInputShrink/TextInputShrink';
import { MerchantNotification } from 'types';

import { MerchantsShowNotificationsGatewayRuleUpdatedField } from '../MerchantsShowNotificationsGatewayRuleUpdatedField/MerchantsShowNotificationsGatewayRuleUpdatedField';

export enum MerchantsNotificationsTypes {
  Reddy = 'reddy',
  Email = 'email',
}

export type MerchantsShowNotificationsFormValues = {
  active: boolean;
  chatId?: string;
  email?: string;
  token?: string;
  type: string;
  event: Record<string, boolean>;
};

export const MerchantsShowNotificationsForm = () => {
  const record = useRecordContext<MerchantNotification>();
  const { data: eventNames } = useQuery(
    'merchant_notification_event_names',
    getMerchantNotificationsEventNames,
  );
  const { setValue } = useFormContext<MerchantsShowNotificationsFormValues>();
  const type = useWatch({ name: 'type' });

  useEffect(() => {
    setValue('email', '');
    setValue('chatId', '');
    setValue('token', '');
  }, [type]);

  useEffect(() => {
    if (record) {
      if (record.credentials?.email) {
        setValue('type', MerchantsNotificationsTypes.Email);
        setValue('email', record.credentials.email);
      }
      if (record.credentials?.chatId) {
        setValue('type', MerchantsNotificationsTypes.Reddy);
        setValue('chatId', record.credentials.chatId.toString());
        record.credentials?.token && setValue('token', record.credentials.token);
      }
      if (record.events.length) {
        record.events.forEach(({ name: recordEvent }) => {
          eventNames?.forEach(({ name: eventName }) => {
            if (recordEvent.name === eventName) {
              setValue(`event.${eventName}`, true);
            }
          });
        });
      }
    }
  }, [eventNames]);

  useEffect(() => {
    if (record) {
      if (record.credentials?.email) {
        setValue('email', record.credentials.email);
      }
      if (record.credentials?.chatId) {
        setValue('chatId', record.credentials.chatId.toString());
        record.credentials?.token && setValue('token', record.credentials.token);
      }
    }
  }, [type]);

  return (
    <CardContent sx={{ paddingTop: '0!important', width: '552px' }}>
      <BooleanInput label="Активен" source="active" sx={{ marginBottom: '24px' }} />
      <SelectInputShrink
        choices={[
          { id: MerchantsNotificationsTypes.Reddy, label: MerchantsNotificationsTypes.Reddy },
          { id: MerchantsNotificationsTypes.Email, label: MerchantsNotificationsTypes.Email },
        ]}
        defaultValue={
          record &&
          (record.credentials?.email
            ? MerchantsNotificationsTypes.Email
            : MerchantsNotificationsTypes.Reddy)
        }
        fullWidth
        helperText={false}
        label="Тип уведомления"
        name="type"
        optionText="label"
        size="medium"
        source="type"
        sx={{ marginBottom: '24px!important' }}
        validate={required()}
        variant="outlined"
      />
      {type === MerchantsNotificationsTypes.Reddy && (
        <>
          <TextInputShrink
            fullWidth
            helperText={false}
            label="ID канала"
            name="chatId"
            size="medium"
            source="chatId"
            sx={{ marginBottom: '24px!important' }}
            validate={[required(), number()]}
            variant="outlined"
          />
          <TextInputShrink
            fullWidth
            helperText={false}
            label="Токен"
            name="token"
            size="medium"
            source="token"
            validate={[required()]}
            variant="outlined"
          />
        </>
      )}
      {type === MerchantsNotificationsTypes.Email && (
        <TextInputShrink
          fullWidth
          helperText={false}
          label="Email"
          name="email"
          size="medium"
          source="email"
          validate={[validateEmail(), required()]}
          variant="outlined"
        />
      )}
      {eventNames?.map((props) => (
        <MerchantsShowNotificationsGatewayRuleUpdatedField {...props} key={props.value} />
      ))}
    </CardContent>
  );
};
