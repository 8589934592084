import { usePermissions } from 'hooks/usePermissions';
import { Stack } from 'shared/mui/Stack/Stack';
import { Typography } from 'shared/mui/Typography';
import { FunctionField } from 'shared/react-admin/Fields';
import { MerchantNotification, Permission } from 'types';

import { MerchantsShowNotificationsDeliveryChannelFieldSwitch } from './MerchantsShowNotificationsDeliveryChannelFieldSwitch/MerchantsShowNotificationsDeliveryChannelFieldSwitch';
import { useGetNotificationTransports } from '../../hooks/useGetNotificationTransports';
import { getNotificationTransportName } from '../../utils/getNotificationTransportName';

type MerchantsShowNotificationsDeliveryChannelFieldProps = { label: string };

export const MerchantsShowNotificationsDeliveryChannelField: React.FC<
  MerchantsShowNotificationsDeliveryChannelFieldProps
> = ({ label }) => {
  const { notificationTransports } = useGetNotificationTransports();
  const { hasResourcePermissions } = usePermissions();

  return (
    <FunctionField
      label={label}
      render={(record: MerchantNotification) => {
        const notificationTransportName = getNotificationTransportName(
          record?.notificationTransport,
          notificationTransports,
        );
        return (
          <Stack alignItems="center" direction="row">
            {hasResourcePermissions('merchant_notification_settings', [
              Permission.Full,
              Permission.Update,
            ]) && (
              <MerchantsShowNotificationsDeliveryChannelFieldSwitch
                notificationTransportName={notificationTransportName}
              />
            )}
            <Typography component="span">{notificationTransportName?.toLowerCase()}</Typography>
          </Stack>
        );
      }}
      sortable={false}
      source="deliveryChannel"
      sx={{ position: 'absolute', top: '4px' }}
    />
  );
};
