import { useInput, InputHelperText, FieldTitle } from 'react-admin';
import { TextField } from 'shared/mui/TextField/TextField';
import { AutocompleteInputProps } from 'shared/react-admin/Inputs/AutocompleteInput/AutocompleteInput';

import { StyledAutocompleteInput } from './AutocompleteInputShrink.styled';

export type AutocompleteInputShrinkProps = AutocompleteInputProps;

export const AutocompleteInputShrink: React.FC<AutocompleteInputShrinkProps> = ({
  sx = {
    '.MuiFormControl-root': {
      marginBottom: 0,
      marginTop: 0,
    },
  },
  isRequired: isRequiredOverride,
  ...props
}) => {
  const {
    fieldState: { error, invalid, isTouched },
    formState: { isSubmitted },
  } = useInput({
    source: props.source || '',
    isRequired: isRequiredOverride,
  });

  return (
    <StyledAutocompleteInput
      renderInput={(params) => {
        const mergedTextFieldProps = {
          ...params.InputProps,
          ...props.TextFieldProps?.InputProps,
        };

        return (
          <TextField
            {...params}
            InputLabelProps={{ shrink: true }}
            InputProps={mergedTextFieldProps}
            error={(isTouched || isSubmitted) && invalid}
            helperText={
              <InputHelperText
                error={error?.message}
                helperText={props.helperText}
                touched={isTouched || isSubmitted}
              />
            }
            label={
              <FieldTitle
                isRequired={(props as any)?.validate?.isRequired || isRequiredOverride}
                label={props.label}
                resource={props.resource}
                source={props.source}
              />
            }
            size={props.size}
            variant={props.variant}
          />
        );
      }}
      sx={sx}
      {...props}
    />
  );
};
