import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { ApiClient } from 'types';

import { getSecurityIssue } from '../../utils/getSecurityIssue';

// Чтобы эта полоска отображалась корректно, необходимо для данной колонки в StyledDatagrid задать position: relative
const getStripeStyle = (color: string) => ({
  '&::before': {
    content: '""',
    position: 'absolute',
    left: 0,
    top: 0,
    width: '4px',
    height: '100%',
    backgroundColor: color,
  },
});

export const ApiClientsListIdField: React.FC<Omit<FunctionFieldProps, 'render'>> = (props) => {
  return (
    <FunctionField
      render={(record: ApiClient) => {
        const securityIssue = getSecurityIssue(record?.securityIssues[0]);

        return <Typography sx={getStripeStyle(securityIssue?.color)}>{record.originId}</Typography>;
      }}
      {...props}
    />
  );
};
