import { useState } from 'react';

import VisibilityIcon from '@mui/icons-material/Visibility';
import { useRecordContext, Button, FieldProps } from 'react-admin';
import { Typography } from 'shared/mui/Typography';

export type SensitiveDataFieldProps = {
  label?: string;
  source: string;
} & FieldProps;

export const SensitiveDataField = ({ source }: SensitiveDataFieldProps): JSX.Element => {
  const record = useRecordContext();
  const [visible, setVisible] = useState(
    record.sensitive ? !record.sensitive.includes('value') : false,
  );

  if (!record[source]) {
    return <></>;
  }

  if (!visible) {
    return (
      <Button label="Показать" onClick={() => setVisible(true)} startIcon={<VisibilityIcon />} />
    );
  }

  return (
    <Typography component="span" variant="body2">
      {record[source]}
    </Typography>
  );
};
