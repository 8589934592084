import { styled } from '@mui/material';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { Create } from 'shared/react-admin/Create/Create';
import { JsonInput } from 'shared/react-admin/Inputs/JsonInputIntrospected/JsonInput';
import { appColors } from 'themes/variables';

export const CreateStyled = styled(Create)({
  margin: 0,
  width: '600px',
  '& .RaCreate-main': {
    marginTop: 0,
  },
});

export const CardContentStyled = styled(CardContent)({
  display: 'flex',
  flexDirection: 'column',
  gap: '4px',

  '&:first-child': {
    borderRight: `1px solid ${appColors.divider}`,
  },
});

export const JsonInputStyled = styled(JsonInput)({
  '& .jer-editor-container': {
    background: '#fff !important',
    border: `1px solid ${appColors.inputBorder}`,
    maxWidth: '707px !important',
    height: '151px',
  },
});
