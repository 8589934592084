import {
  getMerchantNotifications,
  GetMerchantNotificationsResponse,
  MerchantNotificationsFilters,
} from 'config/requests';
import { useQuery } from 'react-query';

type UseGetMerchantNotificationsReturnValue = {
  isLoading: boolean;
  isFetching: boolean;
} & Partial<GetMerchantNotificationsResponse>;

export function useGetMerchantNotifications(
  filters?: MerchantNotificationsFilters,
): UseGetMerchantNotificationsReturnValue {
  const { data, isLoading, isFetching } = useQuery(
    ['merchant_notification_settings', filters],
    () => getMerchantNotifications(filters),
  );

  return {
    merchantNotifications: data?.merchantNotifications,
    totalItems: data?.totalItems,
    isLoading,
    isFetching,
  };
}
