import { Typography } from 'shared/mui/Typography';
import { FunctionField, FunctionFieldProps } from 'shared/react-admin/Fields';
import { ApiClient } from 'types';

import { getSecurityIssue } from '../../utils/getSecurityIssue';

export const ApiClientsListSecurityIssuesField: React.FC<Omit<FunctionFieldProps, 'render'>> = (
  props,
) => {
  return (
    <FunctionField
      render={(record: ApiClient) => {
        const securityIssue = getSecurityIssue(record?.securityIssues[0]);

        return (
          <Typography color={securityIssue?.color} fontSize={14}>
            {securityIssue?.title}
          </Typography>
        );
      }}
      {...props}
    />
  );
};
