import { EMPTY_FIELD } from 'constants/emptyField';
import { Role, ROLES_ICONS, ROLES_SELECT_ITEMS } from 'constants/roles';

import { useMemo } from 'react';

import { VpnKey } from '@mui/icons-material';
import { useRecordModal } from 'hooks/useRecordModal';
import { FunctionField } from 'react-admin';
import { FormProvider } from 'react-hook-form';
import { Button, CreateButton } from 'shared/mui/Buttons';
import { Chip } from 'shared/mui/Chips';
import { DeleteIconButton, EditLinkIconButton, ShowLinkIconButton } from 'shared/mui/IconButtons';
import { IconLabel } from 'shared/mui/Labels/IconLabel/IconLabel';
import { FilterContextProvider } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterApplyButton,
  FilterButton,
  FilterClearButton,
  FilterResetButton,
} from 'shared/mui/NewFilter/FilterButtons';
import { FilterChipsToolbar } from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChipsToolbar';
import {
  FilterDrawer,
  FilterDrawerActionsButtons,
  FilterDrawerContent,
} from 'shared/mui/NewFilter/FilterDrawer';
import { FilterToolbar } from 'shared/mui/NewFilter/FilterToolbar/FilterToolbar';
import { useFilters } from 'shared/mui/NewFilter/hooks/useFilters';
import { PageHeader } from 'shared/mui/PageHeader';
import { ExportButton } from 'shared/react-admin/Buttons';
import { DateTimeField, ListField } from 'shared/react-admin/Fields';
import { TooltipTextField } from 'shared/react-admin/Fields/TooltipTextField/TooltipTextField';
import { TooltipTextLinkField } from 'shared/react-admin/Fields/TooltipTextLinkField/TooltipTextLinkField';
import { ActionsSlots } from 'shared/react-admin/Table/ActionsSlots/ActionsSlots';
import { cleanEmpty } from 'utils/cleanEmpty';

import { StyledDatagrid } from './ApiClientsList.styled';
import { ApiClientsListActiveField } from './ApiClientsListActiveField/ApiClientsListActiveField';
import { ApiClientsListApiKeyShowDialog } from './ApiClientsListApiKeyShowDialog/ApiClientsListApiKeyShowDialog';
import { ApiClientsListFilter } from './ApiClientsListFilter';
import { ApiClientsListFilterChips } from './ApiClientsListFilterChips';
import { ApiClientsListIdField } from './ApiClientsListIdField/ApiClientsListIdField';
import { ApiClientsListMerchantField } from './ApiClientsListMerchantField/ApiClientsListMerchantField';
import { ApiClientsListSecurityIssuesField } from './ApiClientsListSecurityIssuesField/ApiClientsListSecurityIssuesField';
import { usePermissions } from '../../../hooks/usePermissions';
import { UserActionsIconButton } from '../../../shared/mui/IconButtons/UserActionsIconButton/UserActionsIconButton';
import { ApiClient, Permission, SecurityIssue } from '../../../types';
import { AppList } from '../../Common/List';
import { ApiClientsDeleteDialog } from '../components/ApiClientsDeleteDialog/ApiClientsDeleteDialog';

export type ApiClientsListFilterFormValues = {
  name: string | null;
  roles: Role.Merchant | Role.Service | null;
  merchant: string | null;
  securityIssues: SecurityIssue | null;
};

export const ApiClientsList = () => {
  const { hasResourceAccess, hasResourcePermissions } = usePermissions();
  const {
    record: recordDelete,
    handleOpenRecordModal: handleOpenRecordModalDelete,
    openRecordModal: openRecordModalDelete,
    handleCloseRecordModal: handleCloseRecordModalDelete,
  } = useRecordModal<ApiClient>();
  const {
    record: recordApiKey,
    handleOpenRecordModal: handleOpenRecordModalApiKey,
    openRecordModal: openRecordModalApiKey,
    handleCloseRecordModal: handleCloseRecordModalApiKey,
  } = useRecordModal<ApiClient>();

  const { form, appliedFilters, setAppliedFilter, onSubmit, onReset, openDrawer, toggleDrawer } =
    useFilters<ApiClientsListFilterFormValues>({
      mode: 'all',
      defaultValues: {
        name: null,
        roles: null,
        merchant: null,
        securityIssues: null,
      },
    });

  const listFilters = useMemo(() => {
    return cleanEmpty({
      ...appliedFilters,
    });
  }, [appliedFilters]);

  const isListFilterApplied = Object.keys(listFilters).length > 0;

  return (
    <AppList empty={false} filter={listFilters}>
      <PageHeader
        actions={[
          <CreateButton key="create_client_api" />,
          <ExportButton key="export_client_api" />,
        ]}
        headerTitle={{
          titleText: 'Клиенты API',
          tooltipText:
            'Приложение, выполняющее запросы к API Kubera. Например, сайт мерчанта или его мобильное приложение.',
        }}
      />
      <FilterToolbar
        leftActionsSlot={[
          <FilterButton key="filter" onClick={toggleDrawer(true)} />,
          <FilterClearButton
            key="clear-filter"
            onClick={() => {
              onReset();
            }}
            visible={isListFilterApplied}
          />,
        ]}
      />
      <FilterDrawer onClose={toggleDrawer(false)} open={openDrawer}>
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <FilterDrawerContent>
              <ApiClientsListFilter />
            </FilterDrawerContent>
            <FilterDrawerActionsButtons>
              <FilterApplyButton type="submit" />
              <FilterResetButton label="Очистить" onReset={onReset} />
            </FilterDrawerActionsButtons>
          </form>
        </FormProvider>
      </FilterDrawer>
      <FilterChipsToolbar appliedFilters={appliedFilters}>
        <FilterContextProvider appliedFilters={appliedFilters} setAppliedFilter={setAppliedFilter}>
          <FormProvider {...form}>
            <ApiClientsListFilterChips />
          </FormProvider>
        </FilterContextProvider>
      </FilterChipsToolbar>
      <StyledDatagrid bulkActionButtons={false}>
        <ApiClientsListIdField label="ID" source="id" />
        <ApiClientsListActiveField label="-" source="active" />
        <TooltipTextLinkField label="Название" resource="api_clients" source="name" type="show" />
        <TooltipTextField label="Алиас" source="alias" />
        <ListField
          array={ROLES_SELECT_ITEMS}
          label="Доступ"
          renderItem={({ id, name }) => <Chip icon={ROLES_ICONS[id]} key={id} label={name} />}
          sortable={false}
          source="roles"
        />
        {hasResourceAccess('merchants') && <ApiClientsListMerchantField label="Мерчанты" />}
        {hasResourceAccess('api_keys') && (
          <FunctionField
            label={<IconLabel icon={VpnKey} label="API ключи" />}
            render={(record: ApiClient) => {
              return record.apiKeys?.length ? (
                <Button
                  label="Показать"
                  onClick={() => handleOpenRecordModalApiKey(record)}
                  startIcon={<VpnKey />}
                  sx={{ top: '-8px' }}
                />
              ) : (
                EMPTY_FIELD
              );
            }}
            source="apiKeys"
          />
        )}
        <ApiClientsListSecurityIssuesField
          label="Уведомления безопасности"
          source="securityIssues"
        />
        <DateTimeField label="Дата создания" source="createdAt" />
        <DateTimeField label="Дата редактир." source="updatedAt" />
        <ActionsSlots
          render={(record: ApiClient) => (
            <>
              {hasResourceAccess('user_actions') && (
                <UserActionsIconButton entityName="api_client" />
              )}
              <ShowLinkIconButton />
              {hasResourcePermissions('api_clients', [Permission.Full, Permission.Update]) && (
                <EditLinkIconButton />
              )}
              {hasResourcePermissions('commissions', [Permission.Full, Permission.Delete]) && (
                <DeleteIconButton onClick={() => handleOpenRecordModalDelete(record)} />
              )}
            </>
          )}
        />
      </StyledDatagrid>
      {openRecordModalDelete && (
        <ApiClientsDeleteDialog
          onClose={handleCloseRecordModalDelete}
          onSuccess={handleCloseRecordModalDelete}
          open={openRecordModalDelete}
          record={recordDelete}
        />
      )}
      {openRecordModalApiKey && (
        <ApiClientsListApiKeyShowDialog
          onClose={handleCloseRecordModalApiKey}
          open={openRecordModalApiKey}
          record={recordApiKey}
        />
      )}
    </AppList>
  );
};
