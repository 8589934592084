import { DNOTE_SERVICE_URL } from 'constants/urls';

import { useGetList } from 'react-admin';
import { Link } from 'react-router-dom';
import { Box } from 'shared/mui/Box';
import { CardHeader } from 'shared/mui/Card';
import { CardContent } from 'shared/mui/Card/CardContent/CardContent';
import { CircularProgress } from 'shared/mui/CircularProgress/CircularProgress';
import { Dialog } from 'shared/mui/Dialogs';
import { TextFieldWithCopyButton } from 'shared/mui/Fields';
import { CloseIconButton } from 'shared/mui/IconButtons';
import { appColors } from 'themes/variables';
import { ApiClient, ApiKey } from 'types';

import { UNLIMITED_PAGINATION_PARAMS } from '../../../../constants';

type Props = {
  open: boolean;
  onClose: VoidFunction;
  record?: ApiClient;
};

export const ApiClientsListApiKeyShowDialog: React.FC<Props> = ({ open, onClose, record }) => {
  const { data: [apiKey] = [], isLoading } = useGetList<ApiKey>(
    'api_keys',
    {
      pagination: UNLIMITED_PAGINATION_PARAMS,
      filter: { apiClient: record?.id },
    },
    { enabled: !!record },
  );

  return (
    <Dialog maxWidth="sm" onClose={onClose} open={open} transitionDuration={0}>
      <CardHeader
        actions={<CloseIconButton key="close-button" onClose={onClose} />}
        sx={{ width: '600px' }}
        title="Ключ API"
      />
      <CardContent>
        {isLoading ? (
          <CircularProgress sx={{ margin: '16px auto' }} />
        ) : (
          <>
            <TextFieldWithCopyButton
              disabled
              fullWidth
              size="medium"
              sx={{ marginTop: 0 }}
              value={apiKey?.value || ''}
              variant="outlined"
            />
            <Box sx={{ marginTop: 2 }}>
              Используйте внутренний сервис{' '}
              <Link
                style={{ color: appColors.primary.main }}
                target="_blank"
                to={DNOTE_SERVICE_URL}
              >
                DNOTE
              </Link>{' '}
              для передачи значения ключа API.
            </Box>
          </>
        )}
      </CardContent>
    </Dialog>
  );
};
