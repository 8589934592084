import { TextInput, useGetList } from 'react-admin';
import { Controller, useFormContext } from 'react-hook-form';
import { DateTimeRangePicker } from 'shared/mui/DatePicker/DateTimeRangePicker/DateTimeRangePicker';
import { useFilterContext } from 'shared/mui/NewFilter/context/filterContext';
import {
  FilterAutocompleteChip,
  FilterFromToChip,
  FilterEnumMultiselectChip,
  FilterEnumAutocompleteChip,
  FilterMultiselectChip,
  FilterDateTimeRangeChip,
} from 'shared/mui/NewFilter/FilterChipsToolbar/FilterChips';
import {
  AutocompleteInputShrink,
  AutocompleteMultiselectInput,
  EnumAutocompleteInputShrink,
} from 'shared/react-admin/Inputs';
import { ReferenceArrayInput, ReferenceInput } from 'shared/react-admin/ReferenceInputs';
import { cleanEmpty } from 'utils/cleanEmpty';

export const OrdersListFilterChips: React.FC = () => {
  const { control, trigger, resetField, setValue, watch, getValues } = useFormContext();
  const { setAppliedFilter, appliedFilters } = useFilterContext();

  const { data: orderStatuses } = useGetList('new/order_statuses');

  const merchantWatched = watch('merchant');
  const providerWatched = watch('provider');
  const gatewayWatched = watch('gateway');

  const triggerAmountLteValidation = () => trigger(['amount.lte']);
  const triggerAmountGteValidation = () => trigger(['amount.gte']);

  return (
    <>
      <FilterDateTimeRangeChip label="Время" name="appliedDate">
        <Controller
          control={control}
          name="appliedDate"
          render={({ field: { onChange, onBlur, value } }) => (
            <DateTimeRangePicker
              label="Время создания / период"
              onBlur={onBlur}
              onChange={onChange}
              value={value}
            />
          )}
        />
      </FilterDateTimeRangeChip>
      <FilterAutocompleteChip
        label="Мерчант"
        name="merchant"
        onApply={() => {
          setAppliedFilter('provider', null);
          setAppliedFilter('gateway', []);
          setAppliedFilter('method', []);
        }}
        onDelete={() => {
          resetField('provider', { keepDirty: true });
          resetField('gateway', { keepDirty: true });
          resetField('method', { keepDirty: true });
          setAppliedFilter('provider', null);
          setAppliedFilter('gateway', []);
          setAppliedFilter('method', []);
        }}
        onReset={() => {
          setValue('provider', appliedFilters?.['provider']);
          setValue('gateway', appliedFilters?.['gateway']);
          setValue('method', appliedFilters?.['method']);

          setAppliedFilter('provider', appliedFilters?.['provider']);
          setAppliedFilter('gateway', appliedFilters?.['gateway']);
          setAppliedFilter('method', appliedFilters?.['method']);
        }}
        resource="merchants"
      >
        <ReferenceInput perPage={100} reference="merchants" source="merchant">
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Мерчант"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterAutocompleteChip
        label="Провайдер"
        name="provider"
        onApply={() => {
          setAppliedFilter('gateway', []);
          setAppliedFilter('method', []);
        }}
        onDelete={() => {
          resetField('gateway', { keepDirty: true });
          resetField('method', { keepDirty: true });
          setAppliedFilter('gateway', []);
          setAppliedFilter('method', []);
        }}
        onReset={() => {
          setValue('gateway', appliedFilters?.['gateway']);
          setValue('method', appliedFilters?.['method']);

          setAppliedFilter('gateway', appliedFilters?.['gateway']);
          setAppliedFilter('method', appliedFilters?.['method']);
        }}
        resource="providers"
      >
        <ReferenceInput
          filter={cleanEmpty({ merchantAccounts: { merchants: merchantWatched } })}
          perPage={100}
          reference="providers"
          source="provider"
        >
          <AutocompleteInputShrink
            filterToQuery={(searchText: string) => ({ name: searchText })}
            fullWidth
            helperText={false}
            label="Провайдер"
            optionText="name"
            variant="outlined"
          />
        </ReferenceInput>
      </FilterAutocompleteChip>
      <FilterMultiselectChip
        label="Шлюзы"
        limitTags={1}
        name="gateway"
        onApply={() => {
          setAppliedFilter('method', []);
        }}
        onDelete={() => {
          setAppliedFilter('method', []);
          resetField('method', { keepDirty: true });
        }}
        onReset={() => {
          setValue('method', appliedFilters?.['method']);

          setAppliedFilter('method', appliedFilters?.['method']);
        }}
        resource="gateways"
      >
        <ReferenceArrayInput
          filter={cleanEmpty({
            merchantAccounts: { merchants: merchantWatched },
            provider: providerWatched,
          })}
          name="gateway"
          perPage={100}
          reference="gateways"
          source="gateway"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Шлюзы"
            limitTags={1}
            name="gateway"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterMultiselectChip label="Методы" limitTags={1} name="method" resource="methods">
        <ReferenceArrayInput
          filter={cleanEmpty({
            gateway: gatewayWatched,
          })}
          name="method"
          perPage={100}
          reference="methods"
          source="method"
        >
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Методы"
            limitTags={1}
            name="method"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterEnumAutocompleteChip label="Направление" name="direction" resource="directions">
        <EnumAutocompleteInputShrink
          filterToQuery={(searchText: string) => ({ description: searchText })}
          fullWidth
          helperText={false}
          label="Направление"
          name="direction"
          optionText="description"
          optionValue="value"
          resource="directions"
          source="direction"
          variant="outlined"
        />
      </FilterEnumAutocompleteChip>
      <FilterEnumMultiselectChip
        label="Статус"
        limitTags={1}
        name="statusNew"
        resource="new/order_statuses"
      >
        <AutocompleteMultiselectInput
          InputLabelProps={{ shrink: true }}
          defaultValue={getValues('statusNew')}
          getOptionLabel={(option, options) =>
            options?.find((opt) => opt.value === option)?.description
          }
          helperText={false}
          label="Статус"
          limitTags={1}
          name="statusNew"
          optionText="description"
          optionValue="value"
          options={orderStatuses || []}
        />
      </FilterEnumMultiselectChip>
      <FilterMultiselectChip label="Валюта" limitTags={1} name="currency" resource="currencies">
        <ReferenceArrayInput name="currency" perPage={50} reference="currencies" source="currency">
          <AutocompleteMultiselectInput
            InputLabelProps={{ shrink: true }}
            filterToQuery={(searchText: string) => ({ name: searchText })}
            helperText={false}
            label="Валюта"
            limitTags={1}
            name="currency"
            optionText="name"
          />
        </ReferenceArrayInput>
      </FilterMultiselectChip>
      <FilterFromToChip
        fromName="amount.gte"
        fromSlot={
          <TextInput
            InputLabelProps={{ shrink: true }}
            label="Сумма от"
            onChange={triggerAmountLteValidation}
            resettable
            source="amount.gte"
            variant="outlined"
          />
        }
        label="Сумма"
        toName="amount.lte"
        toSlot={
          <TextInput
            InputLabelProps={{ shrink: true }}
            label="Сумма до"
            onChange={triggerAmountGteValidation}
            resettable
            source="amount.lte"
            variant="outlined"
          />
        }
      />
    </>
  );
};
