import { useContext, useEffect, useState } from 'react';

import camelcaseKeys from 'camelcase-keys';
import { Layout as BaseLayout, LayoutProps, useRedirect } from 'react-admin';
import { useLocation } from 'react-router-dom';
import { PageLoading } from 'shared/react-admin/Loading';

import { AppBar } from './AppBar';
import { getMenuList } from './Menu/constants/menuList';
import Menu from './Menu/Menu';
import { getUserInfo } from '../config/requests';
import { Role } from '../constants/roles';
import { APIContext } from '../context/apiContext';
import { usePermissions } from '../hooks/usePermissions';
import { Permission } from '../types';

const Layout = (props: LayoutProps): JSX.Element | null => {
  const [title, setTitle] = useState('Kubera');
  const { pathname } = useLocation();
  const { hasResourceAccess, permissions, hasResourcePermissions, roles } = usePermissions();
  const {
    user: { isLoaded },
    setUser,
  } = useContext(APIContext);
  const redirect = useRedirect();

  useEffect(() => {
    if (!isLoaded) {
      getUserInfo()
        .then((res) => {
          setUser({ ...camelcaseKeys(res), isLoaded: true });
        })
        .catch(() => redirect('/login'));
    }
  }, []);

  useEffect(() => {
    if (pathname) {
      let pageTitle = pathname?.split('/')[1]?.replaceAll('_', ' ');
      if (pageTitle) {
        pageTitle = pageTitle[0]?.toUpperCase() + pageTitle.slice(1) + ' - Kubera';
        setTitle(pageTitle);
      }
    }
  }, [pathname]);

  useEffect(() => {
    if (!isLoaded || roles?.includes(Role.Admin)) {
      return;
    }

    const currentId = pathname?.split('/')[1];
    const isAccessOrdersSubPath = currentId === 'orders_bulk_update' && hasResourceAccess('orders');
    if (hasResourceAccess(currentId) || isAccessOrdersSubPath) {
      return;
    }

    const menuLinks = getMenuList()
      .map((menuItem) => {
        if ('resource' in menuItem) {
          return menuItem.resource;
        } else {
          return menuItem.items.map(({ resource }) => resource);
        }
      })
      .flat();

    const firstResource = menuLinks.find((link) =>
      hasResourcePermissions(link, [Permission.Full, Permission.Read]),
    );

    if (!firstResource) {
      return;
    }

    redirect(firstResource);
  }, [pathname, isLoaded, roles, permissions]);

  useEffect(() => {
    if (!localStorage.getItem('token')) {
      redirect('/login');
    }
  }, [pathname]);

  useEffect(() => {
    document.title = title;
  }, [title]);

  return isLoaded ? <BaseLayout {...props} appBar={AppBar} menu={Menu} /> : <PageLoading />;
};

export default Layout;
